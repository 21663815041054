
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Navbar from '@/components/public/Navbar.vue'
import Footer from '@/components/public/Footer.vue'
import SignupWithEmailForm from '@/components/public/SignupWithEmailForm.vue'
import TryForFree from '@/components/public/TryForFree.vue'
import WhyPeopleLove from '@/components/public/WhyPeopleLove.vue'
import WhyTheBest from '@/components/public/WhyTheBest.vue'
import PublicBookCard from '@/components/public/PublicBookCard.vue'

@Options({
  components: {
    Navbar,
    SignupWithEmailForm,
    TryForFree,
    WhyPeopleLove,
    WhyTheBest,
    Footer,
    PublicBookCard,
  },
})
export default class SiteIndex extends Vue {}
