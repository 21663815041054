// We load Sentry dynamically in the index.html, using Sentry loader,
// see https://docs.sentry.io/platforms/javascript/install/loader/
//
// This module performs extra Sentry initializtion on application load.
// The application is expected to call `sentryInit()` in the entry point.

import { IS_LOCAL_BACKEND, IS_DEVELOP, APP_ENV, RELEASE } from '@/init/settings'
import {
  setSentryInstance,
  skipSentryEvent,
  onSkipSentryEventError,
} from '@/services/sentry'
import { type Hub } from '@sentry/types'

// Get 1.5% of performance events on production.
let tracesSampleRate = 0.015
if (IS_DEVELOP) {
  // Get all performance events on develop.
  tracesSampleRate = 1.0
}
if (IS_LOCAL_BACKEND) {
  tracesSampleRate = 0
}

export function sentryConfig(): any {
  return {
    environment: APP_ENV,
    release: RELEASE,
    // Trace only part of all requests, we can increase or decrease this
    // depending on Sentry transactions usage.
    tracesSampleRate: tracesSampleRate,
    replaysSessionSampleRate: tracesSampleRate,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // See https://docs.sentry.io/platforms/javascript/#decluttering-sentry
    // https://docs.sentry.io/accounts/quotas/manage-event-stream-guide/
    // ignoreErrors: Instruct the SDK to never send an error to Sentry if
    // it matches any of the listed error messages. If no message is available,
    // the SDK will try to compare against an underlying exception type and value.
    ignoreErrors: ['UET is not defined', 'static.hotjar.com/c/hotjar'],
    // A list of strings or regex patterns that match error URLs which should
    // be blocked from sending events
    denyUrls: [
      'addthis_widget.js',
      'chrome-extension://',
      'file:///',
      'gtm.js',
      // Facebook flakiness
      /clarity\.js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /connect\.facebook\.net\/en_US\/sdk\.js/i,
      // Pinterest
      /s\.pinimg\.com\/ct/i,
      /\/ct\/lib\/main/i,
      // Google tag manager
      /www\.googletagmanager\.com\//i,
      // Profitwell
      /profitwell\.com\//i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Safari extensions
      /^safari-web-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    beforeSend(event: any, hint: any): any {
      try {
        if (skipSentryEvent(event, hint)) {
          return null // Returning null does not send the event
        }
      } catch (error) {
        onSkipSentryEventError(error)
      }
      return event
    },
  }
}

export function sentryInit(): void {
  if ((window as any)['__PRERENDER_INJECTED']) {
    // Disable Sentry during prerendering (sentry script is not loaded).
    // @ts-ignore
    window.Sentry = {}
  } else {
    const sentryConf = sentryConfig()

    if (!window.Sentry) {
      console.log('Unable to lazy-load Sentry. Ad blocker?')

      const mockFunction = (): void => {
        console.error('Unable to lazy-load Sentry. Ad blocker?')
      }

      window.Sentry = {
        init: mockFunction,
        onLoad: mockFunction,
        configureScope: mockFunction,
        withScope: mockFunction,
        captureException: mockFunction,
        captureMessage: mockFunction,
        addBreadcrumb: mockFunction,
        setTag: mockFunction,
        setExtra: mockFunction,
        setContext: mockFunction,
        setFingerprint: mockFunction,
        setUser: mockFunction,
      } as any
    }

    // Reassign global window.Sentry object to internal Sentry object
    const Sentry = setSentryInstance(window.Sentry as Hub)

    // Lazy-Loading Sentry
    // https://docs.sentry.io/platforms/javascript/install/lazy-load-sentry/
    // We bind onLoad() method to internal Sentry object (from '@/services/sentry')
    // global window.Sentry was reset in setSentryInstance()
    ;(Sentry as any).onLoad(function () {
      ;(Sentry as any).init(sentryConf)
    })
  }
}
