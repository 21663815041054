
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

@Options({})
export default class PublicBookCard extends Vue {
  @Prop() title!: string
  @Prop() author!: string
  @Prop() category!: string
  @Prop() backgroundColor!: string
  @Prop({ default: false }) includeListenButton!: boolean
}
